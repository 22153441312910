import '../scss/index.scss';
import './polyfills';
import { tns } from "tiny-slider/src/tiny-slider"

if (document.querySelector('#testimonials_slider')) {
    var testiSlider = tns({
      container: '#testimonials_slider',
      controls:false,
      nav: true,
      items: 1,
      slideBy: 1,
      autoplay: true,
      loop: true
    });
  }

window.addEventListener('load', () => {
    document.querySelectorAll('.inspiration_block__image img').forEach(image => {
        image.removeAttribute('width');
        image.removeAttribute('height');
    });
});